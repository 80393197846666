import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Fonction pour retourner l'état initial
const getDefaultState = () => {
  return {
    gDefaut: {
      gSession: null,
      gTopHeader: '',
      gGpi_adh: '',
      gPic_adh: '',
      gGart_adh: 'N',
      gCod_metDefaut: 'VL',
      gLinkingTargetTypeDefaut: 'P',
      gPortailDefaut: 'portailVL',
      gLibellePortailDefaut: 'Pièces Auto',
      gIconPortailDefaut: 'piece',
      gPla_adh: '',
    },
    gInfoPanier: {
      gTotalQtePanier: '',
      gTotalMntPanier: '',
    },
    gInfoVehicule: {
      gKtype: '',
      gImmat: '',
      gVin: '',
      gTyp: 'I',
      gTabInfoVeh: '',
    },
    gNavigation: {
      gParentNodeId: '',
      gIdTecdoc: '',  
      gGroupName: '',
    },
    gFicheArticle: {
      gArticleId: '',
      gParentNodeId: '',
      gId_tecdoc: '',
      gId_fitarfou: '',
      gReliability: '',
      gArticleNo: '',
      gId_fiadf: ''
    }
  };
};

export default createStore({
  state: getDefaultState(),
  getters: {},
  mutations: {
    // Mutations pour réinitialiser les sous-états
    resetgInfoVehiculeState(state) {
      state.gInfoVehicule = getDefaultState().gInfoVehicule;
    },
    resetgNavigationState(state) {
      state.gNavigation = getDefaultState().gNavigation;
    },
    resetgInfoPanierState(state) {
      state.gInfoPanier = getDefaultState().gInfoPanier;
    },
    resetgDefautState(state) {
      state.gDefaut = getDefaultState().gDefaut;
    },
    resetgFicheArticle(state) {
      state.gFicheArticle = getDefaultState().gFicheArticle;
    },
    // Mutations pour mettre à jour les informations de la fiche article
    setgArticleId(state, value) {
      state.gFicheArticle.gArticleId = value;
    },
    setgParentNodeIdFiche(state, value) {
      state.gFicheArticle.gParentNodeId = value;
    },
    setgId_tecdoc(state, value) {
      state.gFicheArticle.gId_tecdoc = value;
    },
   
    setgId_fitarfou(state, value) {
      state.gFicheArticle.gId_fitarfou = value;
    },
    setgReliability(state, value) {
      state.gFicheArticle.gReliability = value;
    },
    setgArticleNo(state, value) {
      state.gFicheArticle.gArticleNo = value;
    },
    setgId_fiadf(state, value) {
      state.gFicheArticle.gId_fiadf = value;
    },
    // Mutations pour mettre à jour les informations de navigation
    setgParentNodeIdNavigation(state, value) {
      state.gNavigation.gParentNodeId = value;
    },
    setgIdTecdoc(state, value) {
      state.gNavigation.gIdTecdoc = value;
    },
    setgGroupName(state, value) {
      state.gNavigation.gGroupName = value;
    },
    // Mutations pour mettre à jour les informations du panier
    setgTotalQtePanier(state, value) {
      state.gInfoPanier.gTotalQtePanier = value;
    },
    setgTotalMntPanier(state, value) {
      state.gInfoPanier.gTotalMntPanier = value;
    },
    // Mutations pour mettre à jour les valeurs dans gDefaut
    setgLibellePortailDefaut(state, value) {
      state.gDefaut.gLibellePortailDefaut = value;
    },
    setgSession(state, value) {
      state.gDefaut.gSession = value;
    },
    setgLinkingTargetTypeDefaut(state, value) {
      state.gDefaut.gLinkingTargetTypeDefaut = value;
    },
    setgPortailDefaut(state, value) {
      state.gDefaut.gPortailDefaut = value;
    },
    setgCod_metDefaut(state, value) {
      state.gDefaut.gCod_metDefaut = value;
    },
    // Mutations pour mettre à jour les informations du véhicule
    setgKtype(state, value) {
      state.gInfoVehicule.gKtype = value;
    },
    setgTyp(state, value) {
      state.gInfoVehicule.gTyp = value;
    },
    setgImmat(state, value) {
      state.gInfoVehicule.gImmat = value;
    },
    setgVin(state, value) {
      state.gInfoVehicule.gVin = value;
    },
    setgInfoVeh(state, value) {
      state.gInfoVehicule.gTabInfoVeh = value;
    },
    // Mutations pour mettre à jour les informations d'adhérent
    setgGpi_adh(state, value) {
      state.gDefaut.gGpi_adh = value;
    },
    setgPla_adh(state, value) {
      state.gDefaut.gPla_adh = value;
    },
    setgPic_adh(state, value) {
      state.gDefaut.gPic_adh = value;
    },
    setgGart_adh(state, value) {
      state.gDefaut.gGart_adh = value;
    },
    setgTopHeader(state, value) {
      state.gDefaut.gTopHeader = value;
    },
    // Mutation pour réinitialiser tout l'état
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    }
  },
  plugins: [createPersistedState()]
});
